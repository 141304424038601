// extracted by mini-css-extract-plugin
export var addInvoiceFormBody = "invoice-details-module--add-invoice-form-body--b5351";
export var bodyBase = "invoice-details-module--body-base--b90da invoice-details-module--site-font--a5056";
export var bodyLarge = "invoice-details-module--body-large--bb01b invoice-details-module--body-base--b90da invoice-details-module--site-font--a5056";
export var bodyLargeBold = "invoice-details-module--body-large-bold--28a09 invoice-details-module--body-base--b90da invoice-details-module--site-font--a5056";
export var bodyRegular = "invoice-details-module--body-regular--8dc49 invoice-details-module--body-base--b90da invoice-details-module--site-font--a5056";
export var bodyRegularBold = "invoice-details-module--body-regular-bold--a6aeb invoice-details-module--body-base--b90da invoice-details-module--site-font--a5056";
export var bodySmall = "invoice-details-module--body-small--660e9 invoice-details-module--body-base--b90da invoice-details-module--site-font--a5056";
export var bodySmallBold = "invoice-details-module--body-small-bold--8b084 invoice-details-module--body-base--b90da invoice-details-module--site-font--a5056";
export var borderTop = "invoice-details-module--border-top--88842";
export var breakWordContainer = "invoice-details-module--break-word-container--b5798";
export var buttonIconBase = "invoice-details-module--button-icon-base--98c1a";
export var clickLink = "invoice-details-module--click-link--f1338";
export var container = "invoice-details-module--container--c1b40";
export var dropdownBase = "invoice-details-module--dropdown-base--a0f7b";
export var dropdownSelectBase = "invoice-details-module--dropdown-select-base--c959d invoice-details-module--text-input--ccc02";
export var flexCol = "invoice-details-module--flex-col--496ed";
export var formErrorMessage = "invoice-details-module--form-error-message--8e975";
export var h3 = "invoice-details-module--h3--f3dec";
export var h4 = "invoice-details-module--h4--a258b";
export var hoverLink = "invoice-details-module--hover-link--bcd57";
export var hoverRow = "invoice-details-module--hover-row--fa615";
export var membershipContainer = "invoice-details-module--membership-container--a51e9 invoice-details-module--flex-col--496ed invoice-details-module--primary-border--f9b5d";
export var membershipHeader = "invoice-details-module--membership-header--ee172";
export var membershipHeading = "invoice-details-module--membership-heading--f38b2";
export var membershipLabel = "invoice-details-module--membership-label--d2f2b";
export var moreFiltersBorderClass = "invoice-details-module--more-filters-border-class--d6797";
export var pageBg = "invoice-details-module--page-bg--b4218";
export var panel = "invoice-details-module--panel--613b2";
export var pointer = "invoice-details-module--pointer--b7bb6";
export var primaryBorder = "invoice-details-module--primary-border--f9b5d";
export var saveButton = "invoice-details-module--save-button--4fc40";
export var shadowBoxLight = "invoice-details-module--shadow-box-light--a91da";
export var siteFont = "invoice-details-module--site-font--a5056";
export var slideDownAndFade = "invoice-details-module--slideDownAndFade--e1a1b";
export var slideLeftAndFade = "invoice-details-module--slideLeftAndFade--8c9e7";
export var slideRightAndFade = "invoice-details-module--slideRightAndFade--f567f";
export var slideUpAndFade = "invoice-details-module--slideUpAndFade--c4671";
export var statusDecoration = "invoice-details-module--status-decoration--f7429";
export var textInput = "invoice-details-module--text-input--ccc02";
export var textInverted = "invoice-details-module--text-inverted--4e571";
export var textMediumDark = "invoice-details-module--text-medium-dark--606b4";
export var title = "invoice-details-module--title--88a0b";
export var tooltipFont = "invoice-details-module--tooltipFont--e860c";
export var unstyledButton = "invoice-details-module--unstyled-button--bfee0";