import React from 'react';

import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import { InvoiceDetails } from 'src/components/saas-memberships/invoice-details/invoice-details';
import SEO from 'src/components/seo';

const InvoiceDetailsPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="Saas Membership Invoice Details" />
      <PrivateRoute>
        <InvoiceDetails />
      </PrivateRoute>
    </Layout>
  );
};

export default InvoiceDetailsPage;
