/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * AgeCategoryType
 */
export enum AgeCategoryType {
  COMBINED = 'COMBINED',
  INDIVIDUAL = 'INDIVIDUAL',
}

/**
 * To what granularity are ages calculated for the puropses of age eligibilty checks.
 */
export enum AgeComparisonRulePrecision {
  DAY = 'DAY',
  MONTH = 'MONTH',
  SEASON = 'SEASON',
  YEAR = 'YEAR',
}

/**
 * Ball colour.
 */
export enum BallColour {
  GREEN = 'GREEN',
  ORANGE = 'ORANGE',
  RED = 'RED',
  YELLOW = 'YELLOW',
}

/**
 * Level Category
 */
export enum Category {
  ADULT = 'ADULT',
  JUNIOR = 'JUNIOR',
  PICKLE_BALL = 'PICKLE_BALL',
  WHEELCHAIR = 'WHEELCHAIR',
  WTN_PLAY = 'WTN_PLAY',
}

/**
 * CheckIn requirement options.
 */
export enum CheckIn {
  FORBIDDEN = 'FORBIDDEN',
  MANDATORY = 'MANDATORY',
  OPTIONAL = 'OPTIONAL',
}

export enum Currency {
  AUD = 'AUD',
  CRC = 'CRC',
  EUR = 'EUR',
  GBP = 'GBP',
  SGD = 'SGD',
  TTD = 'TTD',
  USD = 'USD',
  CAD = 'CAD',
}

/**
 * Direction of sort
 */
export enum Direction {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING',
}

/**
 * Format of an Event, describing the overall structure of the Event.
 */
export enum EventFormat {
  CC = 'CC',
  COMP = 'COMP',
  FIC = 'FIC',
  FIC16 = 'FIC16',
  FICQ = 'FICQ',
  FICSF = 'FICSF',
  FMLC = 'FMLC',
  KO = 'KO',
  MFIC = 'MFIC',
  RR = 'RR',
  RRPO = 'RRPO',
  VC = 'VC',
  AD_HOC = 'AD_HOC',
}

/**
 * Division event type.
 */
export enum EventTypeEnum {
  DOUBLES = 'DOUBLES',
  SINGLES = 'SINGLES',
  TEAM = 'TEAM',
}

/**
 * Division family event type.
 */
export enum FamilyType {
  FATHER_DAUGHTER = 'FATHER_DAUGHTER',
  FATHER_SON = 'FATHER_SON',
  GRANDFATHER_GRANDSON = 'GRANDFATHER_GRANDSON',
  HUSBAND_WIFE = 'HUSBAND_WIFE',
  MOTHER_DAUGHTER = 'MOTHER_DAUGHTER',
  MOTHER_SON = 'MOTHER_SON',
  SAME_GENDER_FEMALE = 'SAME_GENDER_FEMALE',
  SAME_GENDER_MALE = 'SAME_GENDER_MALE',
}

/**
 * Gender.
 */
export enum Gender {
  BOYS = 'BOYS',
  COED = 'COED',
  GIRLS = 'GIRLS',
  MIXED = 'MIXED',
}

/**
 * Grouping option for Tournaments.
 */
export enum GroupingType {
  ALL = 'ALL',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
}

/**
 * Propeties of a LevelConfiguration.
 */
export enum LevelConfigurationProperty {
  ADDITIONAL_PLAYER_INFORMATION = 'ADDITIONAL_PLAYER_INFORMATION',
  ADORNMENTS = 'ADORNMENTS',
  AGE_COMPARISON_AGAINST_ENDDATE = 'AGE_COMPARISON_AGAINST_ENDDATE',
  AGE_COMPARISON_PRECISION = 'AGE_COMPARISON_PRECISION',
  ALLOWS_NONCONSECUTIVE_DAYS = 'ALLOWS_NONCONSECUTIVE_DAYS',
  ALLOW_ADD_INDIVIDUAL_PLAYERS = 'ALLOW_ADD_INDIVIDUAL_PLAYERS',
  ALLOW_ADD_PLAYERS_FROM_TEAMS = 'ALLOW_ADD_PLAYERS_FROM_TEAMS',
  ENFORCE_SCORECARD_RULES = 'ENFORCE_SCORECARD_RULES',
  ENFORCE_SCORECARD_GENDER = 'ENFORCE_SCORECARD_GENDER',
  ENFORCE_SCORECARD_AGE = 'ENFORCE_SCORECARD_AGE',
  ALLOW_BACKDRAW_MOVEMENTS = 'ALLOW_BACKDRAW_MOVEMENTS',
  ALLOW_GENERATE_PLAYOFF_FOR_BACKDRAWS = 'ALLOW_GENERATE_PLAYOFF_FOR_BACKDRAWS',
  ALLOW_QUALIFYING_DRAWS = 'ALLOW_QUALIFYING_DRAWS',
  ALLOW_SPLIT_DRAWS = 'ALLOW_SPLIT_DRAWS',
  ALLOW_TEAM_SUBSTITUTIONS = 'ALLOW_TEAM_SUBSTITUTIONS',
  ALLOW_WILDCARDS = 'ALLOW_WILDCARDS',
  AUTO_APPROVE = 'AUTO_APPROVE',
  BALL_COLOURS = 'BALL_COLOURS',
  CHECKIN_REQUIRED = 'CHECKIN_REQUIRED',
  DESCRIPTION = 'DESCRIPTION',
  DOUBLES_EVENT_FORMATS = 'DOUBLES_EVENT_FORMATS',
  DOUBLES_SCORE_FORMATS = 'DOUBLES_SCORE_FORMATS',
  DOUBLES_SELECTION_PROCESS_CONSTRAINTS = 'DOUBLES_SELECTION_PROCESS_CONSTRAINTS',
  ENABLED = 'ENABLED',
  EVENT_FORMATS = 'EVENT_FORMATS',
  EVENT_LENGTH_RANGE = 'EVENT_LENGTH_RANGE',
  EVENT_TYPES = 'EVENT_TYPES',
  FIRST_MATCH_LOSER_CONSOLATION = 'FIRST_MATCH_LOSER_CONSOLATION',
  GENDERS = 'GENDERS',
  GOLD_SILVER_BRONZE_DRAWS_ENABLED = 'GOLD_SILVER_BRONZE_DRAWS_ENABLED',
  HEAD_TAX = 'HEAD_TAX',
  MAXIMUM_DAYS = 'MAXIMUM_DAYS',
  MINIMUM_DAYS = 'MINIMUM_DAYS',
  NTRP_ENABLED = 'NTRP_ENABLED',
  PLAY_TRACKER = 'PLAY_TRACKER',
  RANKINGS_ENABLED = 'RANKINGS_ENABLED',
  SANCTION_TYPE = 'SANCTION_TYPE',
  SCORE_FORMATS = 'SCORE_FORMATS',
  SEASON_END_DAY = 'SEASON_END_DAY',
  SEASON_END_MONTH = 'SEASON_END_MONTH',
  SELECTION_PROCESS_CONSTRAINTS = 'SELECTION_PROCESS_CONSTRAINTS',
  SKILL_LEVEL = 'SKILL_LEVEL',
  SPLIT_DRAW_EVENT_FORMATS = 'SPLIT_DRAW_EVENT_FORMATS',
  RR_PLAYOFF_DRAW_EVENT_FORMATS = 'RR_PLAYOFF_DRAW_EVENT_FORMATS',
  SUBMISSION_WINDOW = 'SUBMISSION_WINDOW',
  TEAM_EVENT_FORMATS = 'TEAM_EVENT_FORMATS',
  TEAM_SCORE_FORMATS = 'TEAM_SCORE_FORMATS',
  TEAM_SELECTION_PROCESS_CONSTRAINTS = 'TEAM_SELECTION_PROCESS_CONSTRAINTS',
  TOURNAMENT_FEE = 'TOURNAMENT_FEE',
  WTN_RANKINGS_ENABLED = 'WTN_RANKINGS_ENABLED',
}

/**
 * Operator such as EQUALS.
 */
export enum Operator {
  ANY = 'ANY',
  CONTAINS = 'CONTAINS',
  EQUALS = 'EQUALS',
  GTE = 'GTE',
  IN = 'IN',
  IS_NULL = 'IS_NULL',
  LT = 'LT',
  NOT_IN = 'NOT_IN',
  NOT_NULL = 'NOT_NULL',
}

export enum OrganisationContactRelationshipType {
  DIRECTOR = 'DIRECTOR',
}

/**
 * RatingCategoryType
 */
export enum RatingCategoryType {
  COMBINED = 'COMBINED',
  INDIVIDUAL = 'INDIVIDUAL',
}

/**
 * RatingType
 */
export enum RatingType {
  DOUBLES_WTN = 'DOUBLES_WTN',
  NTRP = 'NTRP',
  PTP = 'PTP',
  SINGLES_WTN = 'SINGLES_WTN',
  WTN = 'WTN',
}

/**
 * SafePlay status
 */
export enum SafePlayStatus {
  APPROVED = 'APPROVED',
  EXPIRED = 'EXPIRED',
  NOTAPPROVED = 'NOTAPPROVED',
  NOTSET = 'NOTSET',
  NOTSTARTED = 'NOTSTARTED',
  PENDING = 'PENDING',
}

/**
 * Status through sanctioning flow.
 */
export enum SanctionStatus {
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  SUBMITTED = 'SUBMITTED',
}

/**
 * Level Sanction Type
 */
export enum SanctionType {
  DISTRICT = 'DISTRICT',
  NATIONAL = 'NATIONAL',
  NONE = 'NONE',
  SECTION = 'SECTION',
}

/**
 * Selection instruction in tournament pool
 */
export enum SelectionInstruction {
  ALTERNATE = 'ALTERNATE',
  SELECT = 'SELECT',
  WITHDRAW = 'WITHDRAW',
}

/**
 * Selection process
 */
export enum SelectionProcess {
  BOTTOM_UP_BY_RANKING = 'BOTTOM_UP_BY_RANKING',
  BOTTOM_UP_BY_RATING = 'BOTTOM_UP_BY_RATING',
  BOTTOM_UP_BY_WTN = 'BOTTOM_UP_BY_WTN',
  FIRST_COME_FIRST_SERVED = 'FIRST_COME_FIRST_SERVED',
  MANUAL = 'MANUAL',
  TOP_DOWN_BY_RANKING = 'TOP_DOWN_BY_RANKING',
  TOP_DOWN_BY_RATING = 'TOP_DOWN_BY_RATING',
  TOP_DOWN_BY_WTN = 'TOP_DOWN_BY_WTN',
}

/**
 * Level of skill
 */
export enum SkillLevel {
  ADVANCED = 'ADVANCED',
  BEGINNER = 'BEGINNER',
  HIGH_INTERMEDIATE = 'HIGH_INTERMEDIATE',
  INTERMEDIATE = 'INTERMEDIATE',
  LOW_INTERMEDIATE = 'LOW_INTERMEDIATE',
  NONE = 'NONE',
}

/**
 * Surface used for Event.
 */
export enum Surface {
  ARTIFICIAL = 'ARTIFICIAL',
  CLAY = 'CLAY',
  GRASS = 'GRASS',
  GREEN_CLAY = 'GREEN_CLAY',
  HARD = 'HARD',
  RED_CLAY = 'RED_CLAY',
}

/**
 * Status of a tournament fee payment.
 */
export enum TournamentFeePaymentSatus {
  CHARGE_FAILED = 'CHARGE_FAILED',
  NOT_REQUIRED = 'NOT_REQUIRED',
  PAID = 'PAID',
  PENDING = 'PENDING',
}

/**
 * USTA wheelchair rating.
 */
export enum USTAWheelchairRating {
  A = 'A',
  ANY = 'ANY',
  B = 'B',
  C = 'C',
  D = 'D',
  Q = 'Q',
}

export interface DateRangeWithTimeZoneInput {
  from?: any | null;
  timeZone?: string | null;
  to?: any | null;
}

export interface DirectorExternalIdInput {
  emailAddress?: string | null;
  externalId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  organisationId: any;
}

export interface EventApproved {
  eventId: any;
}

export interface EventDeclined {
  eventId: any;
}

export interface EventLengthRangeInput {
  maximumDays?: number | null;
  minimumDays?: number | null;
}

export interface HeadTaxInput {
  fixedFee: number;
  percentageFee: number;
}

export interface LevelConfigurationOverridesInput {
  additionalPlayerInformation?: string | null;
  adornments?: string | null;
  ageComparisonAgainstEndDate?: boolean | null;
  ageComparisonPrecision?: AgeComparisonRulePrecision | null;
  allowAddIndividualPlayers?: boolean | null;
  allowAddPlayersFromTeams?: boolean | null;
  enforceScorecardGender?: boolean | null;
  enforceScorecardAge?: boolean | null;
  allowBackdrawMovements?: boolean | null;
  allowGeneratePlayOffForBackDraws?: boolean | null;
  allowQualifyingDraws?: boolean | null;
  allowSplitDraws?: boolean | null;
  allowTeamSubstitutions?: boolean | null;
  allowWildcards?: boolean | null;
  allowsNonConsecutiveDays?: boolean | null;
  autoApprove?: boolean | null;
  ballColours?: BallColour[] | null;
  checkInRequired?: CheckIn | null;
  description?: string | null;
  doublesEventFormats?: EventFormat[] | null;
  doublesScoreFormats?: string[] | null;
  doublesSelectionProcessConstraints?: SelectionProcess[] | null;
  enabled?: boolean | null;
  eventFormats?: EventFormat[] | null;
  eventLengthRange?: EventLengthRangeInput | null;
  eventTypes?: EventTypeEnum[] | null;
  firstMatchLoserConsolation?: boolean | null;
  genders?: Gender[] | null;
  goldSilverBronzeDrawsEnabled?: boolean | null;
  headTax?: HeadTaxInput | null;
  lockedProperties?: LevelConfigurationProperty[] | null;
  ntrpEnabled?: boolean | null;
  playTracker?: boolean | null;
  rankingsEnabled?: boolean | null;
  sanctionType?: SanctionType | null;
  scoreFormats?: string[] | null;
  seasonEndDay?: number | null;
  seasonEndMonth?: number | null;
  selectionProcessConstraints?: SelectionProcess[] | null;
  skillLevel?: SkillLevel | null;
  splitDrawEventFormats?: EventFormat[] | null;
  roundRobinPlayoffDrawEventFormats?: EventFormat[] | null;
  submissionWindow?: DateRangeWithTimeZoneInput | null;
  teamEventFormats?: EventFormat[] | null;
  teamScoreFormats?: string[] | null;
  teamSelectionProcessConstraints?: SelectionProcess[] | null;
  tournamentFee?: number | null;
  wtnRankingsEnabled?: boolean | null;
}

export interface OrganisationSettingsInput {
  nonSanctionedTournamentFee?: number | null;
  tournamentDirectorsCanBeUsedByChildOrganisations: boolean;
}

export interface PropertyFilterInput {
  operator: Operator;
  property: string;
  values: string[];
}

export interface PropertySortInput {
  property: string;
  sortDirection: Direction;
}

export interface TournamentFilterInput {
  organisationId?: any | null;
  sanctioningOrganisationId?: any | null;
  userId?: any | null;
}

export interface TournamentPoolInput {
  category: Category;
  countDivisionsAsTournaments?: boolean | null;
  entriesCloseDate?: string | null;
  entriesCloseTime?: number | null;
  entriesOpenDate?: string | null;
  entriesOpenTime?: number | null;
  fromDate: string;
  levelId: any;
  maxDivisionsPerPlayerPerTournament?: number | null;
  maxTournamentsPerPlayer?: number | null;
  name: string;
  organisationId: any;
  priorityPlayerSelection?: boolean | null;
  timeZone?: string | null;
  toDate: string;
  websitePromotion?: boolean | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
