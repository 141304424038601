import React from 'react';

import { useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { meshGatewayClient } from 'src/apollo/client';
import { useOrgId } from 'src/apollo/local-state';
import { GET_ORG_EXTENSIONS } from 'src/components/saas-memberships/saas-memberships-queries';

import 'react-toastify/dist/ReactToastify.css';

import Spinner from 'src/components/spinner/spinner';

import { InvoiceDetailsForm } from './invoice-details-form';

export const InvoiceDetails: React.FC = () => {
  const orgId = useOrgId();

  const { data, loading } = useQuery<any>(GET_ORG_EXTENSIONS, {
    client: meshGatewayClient,
    variables: { clubSparkOrganisationId: orgId },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  if (loading || !data) return <Spinner fullScreen />;

  return <InvoiceDetailsForm existingOrganisation={data?.getOrganisationById} orgId={orgId} />;
};
