import React from 'react';

import { useMutation } from '@apollo/client';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { meshGatewayClient } from 'src/apollo/client';
import Breadcrumbs from 'src/components/breadcrumbs/breadcrumbs';
import Button from 'src/components/button/button';
import Panel from 'src/components/panel/panel';
import { ADD_ORG_EXTENSIONS } from 'src/components/saas-memberships/saas-memberships-queries';
import { PageMaxWidth } from 'src/components/util-components/util-components';
import ROUTES from 'src/utils/routes';
import * as yup from 'yup';

import * as styles from './invoice-details.module.less';

import 'react-toastify/dist/ReactToastify.css';

import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import { Resolver, useForm } from 'react-hook-form';
import Form from 'src/components/form-components/index';
import PageHeader from 'src/components/page-header/page-header';
import { convertInputToExtension } from 'src/components/saas-memberships/utils';
import { getOrganisationById_getOrganisationById } from 'src/graphql-types/saas/getOrganisationById';

enum InvoiceFieldEnums {
  payableTo = 'PAYABLE_TO' as any,
  paymentTerms = 'PAYMENT_TERMS' as any,
  paymentInformation = 'PAYMENT_INSTRUCTIONS' as any,
}

const fieldNames = {
  PAYABLE_TO: 'payableTo',
  PAYMENT_TERMS: 'paymentTerms',
  PAYMENT_INSTRUCTIONS: 'paymentInformation',
};

interface FormData {
  payableTo: string;
  paymentTerms: number | null;
  paymentInformation: {
    data: string;
    charsRemaining: number;
  };
}

interface Props {
  existingOrganisation?: getOrganisationById_getOrganisationById;
  orgId?: string;
}

export const InvoiceDetailsForm = ({ existingOrganisation, orgId }: Props) => {
  const { t } = useTranslation();

  const schema = yup
    .object()
    .shape({
      payableTo: yup.string().required(t('is required', { type: 'invoice payable to' })),
      paymentTerms: yup
        .number()
        .nullable(true) // set to make sure correct error is returned when field is empty
        .transform((_, val) => (val ? Number(val) : null)) // to handle null value
        .required(t('is required', { type: 'payment terms' }))
        .min(0)
        .typeError(t('must be a number', { type: 'payment terms' }))
        .positive(t('must be a positive number', { type: 'payment terms' })),
      paymentInformation: yup
        .object()
        .shape({
          data: yup.string().required(t('is required', { type: 'payment information' })),
          charsRemaining: yup.number().min(0, t('max characters exceeded')),
        })
        .required(t('is required', { type: 'payment information' })),
    })
    .required() as yup.ObjectSchema<FormData>;

  const [addInvoice] = useMutation(ADD_ORG_EXTENSIONS, {
    client: meshGatewayClient,
    onError: () => {
      toast.error(t('error'));
    },
    onCompleted: () => {
      toast.success(t('settings updated'));
      navigate(ROUTES.SAAS_MEMBERSHIP_SETTINGS);
    },
  });

  const methods = useForm<FormData>({
    defaultValues: generateInitialFormValues(existingOrganisation),
    resolver: yupResolver(schema) as Resolver<FormData>,
    reValidateMode: 'onBlur',
    shouldUnregister: false,
  });

  const handleSubmit = async (addInvoiceInput: FormData) => {
    const convertedInvoiceInput = {
      ...addInvoiceInput,
      paymentInformation: addInvoiceInput.paymentInformation.data,
    };

    const extensions = convertInputToExtension(convertedInvoiceInput, InvoiceFieldEnums);

    await addInvoice({
      variables: {
        clubSparkOrganisationId: orgId,
        updateInput: extensions,
      },
    });
  };

  return (
    <PageMaxWidth>
      <Breadcrumbs
        paths={[
          {
            name: t('membership settings'),
            to: `${ROUTES.SAAS_MEMBERSHIP_SETTINGS}`,
            highlight: true,
          },
          {
            name: t('invoice details'),
            active: true,
          },
        ]}
      />
      <Grid container justifyContent="space-between" alignItems="center" className={styles.title}>
        <PageHeader title={t('invoice details title')} />
      </Grid>
      <Panel>
        <p>{t('invoice intro text')} </p>
        <Form methods={methods} onSubmit={handleSubmit}>
          <Form.TextField id={'tf-payableTo'} label={t('invoice payable to')} name="payableTo" />
          <Form.TextField
            id={'tf-paymentTerms'}
            label={t('payment terms')}
            name="paymentTerms"
            placeholder={t('payment terms placeholder text')}
          />
          <Form.TextArea
            id="ta-paymentInformation"
            label={t('payment information')}
            name="paymentInformation"
            maxChars={500}
            placeholder={t('payment information placeholder text')}
          />
          <Button spacing={{ margins: { auto: 'right', md: 'top' } }}>{t('save')}</Button>
        </Form>
      </Panel>
    </PageMaxWidth>
  );
};

export const generateInitialFormValues = (
  organisation: getOrganisationById_getOrganisationById | undefined,
): FormData => {
  const initialValues = {
    payableTo: '',
    paymentTerms: null,
    paymentInformation: {
      data: '',
      charsRemaining: 0,
    },
  };

  if (!organisation?.extensions) return initialValues;

  for (const item of organisation.extensions) {
    const name = item.name;
    const value = item.value;

    switch (name) {
      case InvoiceFieldEnums.paymentInformation.toString():
        initialValues[fieldNames[name]].data = value;
        break;
      case InvoiceFieldEnums.payableTo.toString():
      case InvoiceFieldEnums.paymentTerms.toString():
        initialValues[fieldNames[name]] = value;
        break;
      default:
        break;
    }
  }

  return initialValues;
};
